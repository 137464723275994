.top-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  h1 {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: $heading-color;
  }

  .balance-amt {
    text-align: right;

    label {
      font-weight: normal;
      font-size: 12px;
      color: $heading-color;
      display: block;
    }

    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      color: #e02020;
    }
  }

  .balance-amt-org {
    text-align: right;

    label {
      font-weight: normal;
      font-size: 12px;
      color: $heading-color;
      display: block;
    }

    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      color: #e88107;
    }
  }


  .balance-amt-outer {
    display: flex;

    .balance-amt {
      padding-left: 30px;
    }
  }
}

.top-ribben-loan {
  background: $white;
  padding: 20px;

  .loan-from {
    display: flex;
    align-items: center;

    fieldset {
      width: 100%;
      margin-right: 8px;
    }

    .btn-submit-reset {
      display: flex;

      .btn-primary {
        min-width: 88px;
        margin-right: 10px;
      }

      .btn-reset {
        background: transparent;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        border: 0px;
        color: $heading-color;
        cursor: pointer;
      }
    }

    .material {
      .form-input {
        font-weight: normal;
        font-size: 12px;
        margin-bottom: 0px;
        padding: 11px 15px;
      }
    }

    .react-date-picker__wrapper {
      border: 1px solid #bbbbbb;
      border-radius: 8px;
      box-shadow: 0 0 0;
      width: 100%;
      padding: 15px;
      height: 42px;
      display: flex;
      align-items: center;
    }

    .loanid-dropdown {
      // width: 10%;
      // min-width: 10%;
      // max-width: 10%;
    }

    .loannid-input {
      // width: 10%;
      // min-width: 10%;
      // max-width: 10%;
    }

    .datetype {
      // width: 10.61%;
      // min-width: 10.61%;
      // max-width: 10.61%;
    }

    .datefrom {
      // width: 11%;
      // min-width: 11%;
      // max-width: 11%;
    }

    .react-date-picker__button svg {
      height: 13px !important;
      width: 14px !important;
    }

    .react-date-picker__inputGroup {
      min-width: 78% !important;
      font-size: 12px;
    }

    .react-date-picker {
      width: 100%;
    }

    .sub-status {
      // width: 11%;
      // min-width: 11%;
      // max-width: 11%;
    }

    .executive-dropdown {
      // width: 10%;
      // min-width: 10%;
      // max-width: 10%;
    }

    .citydrop-down {
      width: 9.01%;
      min-width: 9.01%;
      max-width: 9.01%;
    }

    .status {
      // width: 10%;
      // min-width: 10%;
      // max-width: 10%;
    }

    .select-list {
      &:after {
        top: 8px;
        right: 3px;
      }
    }
  }
}

.tab-chips {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  padding: 15px 0px;

  .nav {
    display: flex;
    align-items: center;
  }

  .nav-item {
    span {
      background: rgba($table-bg, 0.6);
      margin-left: 10px;
      font-size: 11px;
      border-radius: 12px;
      min-width: 31px;
      min-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      color: $heading-color;
      font-size: 12px;
      border: 1px solid #e3e7f1;
      background: $white;
      height: 32px;
      border-radius: 16px;
      margin-right: 10px;
      padding: 0px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $heading-color;
      font-size: 12px;
      cursor: pointer;

      &.active {
        background: $accent-color;
        color: $white;

        span {
          background: rgba(0, 151, 178, 0.6);
          color: $white;
        }
      }
    }
  }

  .no-active {
    background: #fff !important;
    color: $heading-color !important;

    span {
      background: rgba(227, 231, 241, 0.6) !important;
      color: $heading-color !important;
    }
  }
}

.loan-table-data {
  background: $white;

  table {
    // margin-top: 24px;
    table-layout: fixed;

    thead {
      tr {
        background: #e3e7f1;
        border-radius: 6px;

        th {
          padding: 15px 15px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          color: $heading-color;
          white-space: nowrap;
          text-align: left;

          &:first-child {
            border-radius: 6px 0 0 6px;
          }

          &:last-child {
            border-radius: 0px 6px 6px 0;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid rgba($heading-color, 0.06);

        td {
          padding: 13px 15px;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          color: rgba($heading-color, 0.6);
          text-align: left;
          // &:nth-child(5) {
          //   width: 110px;
          // }
        }

        &:last-child {
          border-bottom: 0px;
        }
        .history-btn{
          margin-top:5px !important;
        }
        .list-btn-view {
          width: 80px !important;
        }
        // &:first-child {
        //   td {
        //     padding-top: 19px;
        //   }
        // }
        &:hover {
          background: rgba(239, 242, 249, 0.4);

          .history-btn{
            cursor: pointer;
            margin-top:5px !important;
          }
          
          .btn-view,
          .btn-status {
            background: $accent-color;
            color: $white;

            a {
              color: $white;
            }
          }

          td {
            color: $heading-color;
          }
        }
      }
    }

    .ph-no {
      display: block;
    }

    .name-txt {
      white-space: nowrap;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    h1 {
      font-size: 19px;
      font-weight: 500;
    }
  }

  .css-14jk2my-container {
    width: 120px;
    height: 32px !important;
    min-height: 32px !important;
    border-radius: 8px;

    .css-1fhf3k1-control {
      height: 32px !important;
      min-height: 32px !important;
      border-radius: 8px;
    }

    .css-109onse-indicatorSeparator {
      display: none;
    }
  }

  .btn-view,
  .btn-status {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 29px;

    a {
      font-size: 12px;
      color: $white;
      color: $heading-color;

      &:hover {
        color: $white;
        // color: #44d7b6;
      }
    }
  }

  select {
    padding: 0px 12px;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    box-sizing: border-box;
    border-radius: 6px;
    height: 32px;

    &:after {
      content: "\e917";
      font-family: dc !important;
    }
  }

  .css-yk16xz-control {
    height: 32px !important;
    min-height: 32px !important;
  }

  .css-yk16xz-control,
  .css-1pahdxg-control {
    width: 120px;
    height: 32px !important;
    min-height: 32px !important;
  }

  .css-1pahdxg-control {
    .css-tlfecz-indicatorContainer {
      padding: 4px !important;
    }
  }

  .css-26l3qy-menu {
    width: auto;
    white-space: nowrap;
  }

  th,
  td {
    &:nth-child(1) {
      width: 8%;
    }

    &:nth-child(2) {
      width: 9%;
    }

    &:nth-child(3) {
      width: 10%;
    }

    &:nth-child(4) {
      width: 8%;
    }

    &:nth-child(5) {
      width: 8%;
    }

    &:nth-child(6) {
      width: 8%;
    }

    &:nth-child(7) {
      width: 10%;
    }

    &:nth-child(8) {
      width: 12%;
    }

    &:nth-child(9) {
      width: 7%;
    }

    &:nth-child(10) {
      width: 8%;
    }

    &:nth-child(11) {
      width: 7%;
    }

    &:nth-child(12) {
      width: 7.5%;
    }
  }
}

.pagination-outer {
  margin-top: 20px;
}

.tab-content>.tab-pane {
  display: none;
}

.fade:not(.show) {
  opacity: 0;
}

.fade {
  transition: opacity 0.15s linear;
}

.tab-content>.active {
  display: block;
}

/**
   Animation Keyframes
**/
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.image-uploader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0 0;

  .display-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eff2f9;
    // height: 500px;
    width: 100%;
    padding-top: 40px;
    border-radius: 8px;

    &:hover,
    &.drag-over {
      .icon-text-box {
        // background-color: #96a599;
        cursor: pointer;

        // color: #fff;
        // text-shadow: 0px 1px 0 #999;
        .upload-icon {
          // transform: translateY(-20px);
          // animation: bounce 1s ease-in-out infinite;
        }
      }
    }

    .icon-text-box {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      text-shadow: 0px 0px 0 #999;
      transition: background-color 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in, text-shadow 0.2s ease-in;

      .upload-icon {
        width: 60px;
        height: 60px;
        margin: 0;
        box-sizing: border-box;
        transform: translateY(0px);
        transition: transform 0.2s ease-in-out;
        background: #fff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 40px;
        }
      }

      .upload-button,
      .cancel-upload-button {
        margin: 0 10px;
        position: relative;
        z-index: 9999;
      }

      .error-notification {
        animation: fadeInDown 0.5s;
        position: absolute;
        top: 20px;
        background-color: #dfb852;
        border-radius: 5px;
        color: #444;
        padding: 5px 10px;

        p {
          margin: 0;
          text-shadow: none;
        }
      }
    }

    .upload-image-input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.upload-txt {
  padding-top: 24px;

  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $heading-color;
  }

  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: rgba($heading-color, 0.5);
    opacity: 0.5;
  }

  .or-txt {
    text-transform: uppercase;
    padding-top: 20px;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: $heading-color;
  }
}

.file-btn {
  min-width: 177px;
  box-shadow: none;
  margin: 23px 0px 30px;
}

.utr-upload-popup {
  .modal-main {
    width: 583px;
  }
}

.take-over-payment-charge-type-popup {
  .modal-main {
    width: 583px;
  }
}

.download-txt {
  font-size: 12px;
  line-height: 22px;
  font-weight: normal;
  color: rgba($heading-color, 0.6);
  margin-top: 24px;

  a {
    text-decoration: underline;
    color: $heading-color;
  }
}

.payment-upload-succss {
  background: #ffffff;
  border: 1px solid #e6e9ef;
  border-radius: 8px;
  margin: 24px 0 0;
  padding-top: 30px;
  width: 100%;

  .success-icn {
    width: 60px;
    height: 60px;
    background: #44d7b6;
    color: $white;
    border-radius: 100%;
    font-size: 40px;
    margin: 0 auto;
    display: block;
    text-align: center;
  }
  .error-icn {
    @extend .success-icn;
    background: #e02020;
  }

  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: $heading-color;
    padding: 30px 40px;
    text-align: center;
  }

  .upload-btn-txt {
    background: #eff2f9;
    border-radius: 0 0 8px 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $heading-color;
    }

    .button-wrapper {
      position: relative;
      text-align: center;
    }

    .button-wrapper span.label {
      position: relative;
      z-index: 0;
      background: $accent-color;
      box-shadow: 0px 6px 6px rgba(11, 189, 221, 0.25);
      border-radius: 8px;
      height: 44px;
      line-height: 44px;
      font-weight: 500;
      font-size: 13px;
      color: $white;
      cursor: pointer;
      min-width: 177px;
      border: 1px solid $accent-color;
      display: block;
    }

    .upload-box {
      display: inline-block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 50px;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  .upload-error-msg {
    background: #fbe8e8;
    border: 1px solid #f0cece;
    border-radius: 6px;
    padding: 20px;
    margin: 30px 40px;

    span {
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      text-align: center;
      color: #e02020;
      display: block;
    }

    a {
      color: $heading-color;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10px;

      span {
        text-decoration: underline;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: $heading-color;
        padding-right: 10px;
      }

      i {
        font-size: 15px;
      }
    }
  }
}

.upload-file-name {
  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: $heading-color;
    padding: 20px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      background: #fff;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      font-size: 12px;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
    }
  }

  .btn-primary {
    margin-bottom: 40px !important;
    background: #fff;
    color: #000;
    box-shadow: none;
    border: 1px solid #8c8c8c;
    min-width: 195px;
  }
}

.tab-chip-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.pills-bm{
  background: rgb(135,181,248);
  text-align: center;
  border-radius: 15px;
  width: 60px;
}
.pills-takeover{
  background: rgb(236, 68, 68);
  text-align: center;
  border-radius: 2px;
  width: 100px;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
}
.pills-dl{
  background:rgb(255,204,139);
  color: white;
  text-align: center;
  border-radius: 15px;
  width: 60px;
  margin-left: 5px;
}

.pills-container {
  text-align: center;
    display: flex;
    align-items: center;
}

.left-pill {
  margin-right: 10px;
  margin-top: -4px;
}

.payment-update-upload {
  background: $white;
  width: calc(50% - 10px);
  padding: 30px;
  border-radius: 8px;
  min-height: 573px;
}

.indivisul-upload {
  display: block;
  background: $white;
  padding: 30px;
  width: calc(50% - 10px);
  border-radius: 8px;
  min-height: 573px;
}

.update-payment-outer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.update-payment-outer {
  .upload-form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 24px;
  }

  fieldset {
    width: calc(50% - 8px);

    .datepicker input {
      height: 52px;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  .datepicker,
  .css-2b097c-container {
    margin-bottom: 20px;
  }

  .react-date-picker__wrapper {
    background: #ffffff;
    border: 1px solid #bbbbbb;
    border-radius: 8px;
    width: 100%;
    height: 52px;
    padding: 0px 15px;
  }

  .react-date-picker {
    width: 100%;
  }

  .react-date-picker__button {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .css-yk16xz-control {
    height: 52px;
    min-height: 52px;
  }

  .btn-submit {
    .btn-primary {
      min-width: auto;
      width: calc(50% - 8px);
    }
  }

  .css-1pahdxg-control {
    border-color: #bbbbbb !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    height: 52px;
    min-height: 52px !important;
  }

  .css-1pahdxg-control:hover {
    border-color: #bbbbbb !important;
    box-shadow: none;
    outline: none;
    height: 52px;
    border-color: #bbbbbb !important;
    border-radius: 8px !important;
    min-height: 52px !important;
  }

  .financer-payment-update {
    fieldset {
      margin-bottom: 20px;
    }

    .material {
      .form-input {
        margin-bottom: 0px;
      }

      .datepicker {
        margin-bottom: 0px !important;
      }

      .css-2b097c-container {
        margin-bottom: 0px;
      }
    }
  }
}

.update-payment-heading {
  padding: 20px 0;
}

.detail-form-select-bx {
  margin-bottom: 20px;

  .css-2b097c-container {
    height: 52px !important;
  }

  .css-yk16xz-control {
    height: 52px !important;
    border-radius: 8px !important;
    min-height: 52px !important;
  }

  .css-1pahdxg-control {
    height: 52px !important;
    border-radius: 8px !important;
    min-height: 52px !important;
  }

  .form-label {
    top: -23px;
  }
}

.detail-form-select-bx {
  .css-g1d714-ValueContainer {
    padding: 2px 14px;
  }
}

.loan-table-data {
  .css-g1d714-ValueContainer {
    padding: 0px 8px;
  }
}

.uplod-utr-btn {
  padding-top: 20px;

  .btn-primary {
    min-width: 177px;
  }
}

.css-1gpjby2 {
  svg {
    display: none;
  }

  &:after {
    content: "\e917";
    position: absolute;
    right: 3px;
    color: #000;
    font-family: dc !important;
    font-size: 24px;
    bottom: 20px;
  }
}

.table-outer {
  .infinite-scroll-component {
    overflow: inherit !important;
  }
}
.heading-lost {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
}

.loan-table-data {
  .action-btn {
    display: flex;
    li {
      height: 32px;
      width: 32px;
      border-radius: 4px;
      border: 1px solid #e0e0e0;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      cursor: pointer;

      &:last-child {
        margin-right: 0px;
      }
      &:hover{
        background-color: $accent-color;
        border-color: $accent-color;
        i{
          color: $white;
        }
      }
    }
  }
}