.payment-table {
    &.loan-table-data {
        table {
            thead {
                tr {
                    th {
                        white-space: normal;
                        vertical-align: top;
                    }
                }
            }
        }

        th,
        td {
            &:nth-child(1) {
                width: 13%;
            }
            &:nth-child(2) {
                width: 10%;
            }
            &:nth-child(3) {
                width: 10%;
            }

            &:nth-child(4) {
                width: 12%;
            }
            &:nth-child(5) {
                width: 8%;
            }
            &:nth-child(6) {
                width: 13%;
            }
            &:nth-child(7) {
                width: 10%;
            }
            &:nth-child(8) {
                width: 14%;
            }
            &:nth-child(9) {
                width: 10%;
            }
        }
        .ellipsis-text {
            max-width: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.add-refund-popup {
    .modal{
        z-index: 10 !important;
    }
    h2 {
        padding-bottom: 20px;
    }
    .material {
        .form-input {
            padding: 10px 15px;
        }
        .form-label:before {
            top: 11px;
        }
    }
}

.mark-additional-icn {
    margin-left: 15px;
    position: relative;
    .dropdown {
        .dropdown-toggle {
            background: transparent;
            border: 0px;
            font-size: 25px;
            .ic-more_vert {
                color: rgba($heading-color, 0.5);
            }
        }
        &.show {
            .dropdown-menu {
                display: block;
                width: 230px;
                // height: 80px;
                background: #ffffff;
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
                border-radius: 8px;
                inset: inherit !important;
                transform: inherit !important;
                top: 50% !important;
                right: 30px !important;
                text-align: center;
                line-height: 29px;
                padding: 10px 0;
                transform: translateY(-50%) !important;

                &::before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-left: 15px solid $white;
                    position: absolute;
                    right: -15px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                a {
                    color: $heading-color;
                    cursor: pointer;
                    display: block;
                }
                &.show {
                    display: block;
                }
            }
        }
    }
}
.select-loan-id {
    display: flex;
    align-items: center;
    position: relative;
    white-space: nowrap;
}

.allocation-table-pending-data {
    table {
        .w-12 {
            width: 12% !important;
        }
        .w-14 {
            width: 14% !important;
        }
        .w-7 {
            width: 7% !important;
        }

        th {
           white-space: normal !important;
        }
    }
}
.date-label{
    .material {
        .form-label:before {
            top: -10px !important;
            background: white;
            z-index: 9;
            font-size: 12px;
        }
    } 
}

.additional-revenue-checkbx {
    .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
        font-size: 16px;
    }

    .custom-control-label::after {
        top: 1px;
        left: 2px;
    }

    .custom-control-input {
        height: 100%;
        width: 100%;
    }
}

.react-select__menu {
    z-index: 11 !important;
}

.update-pending-payment {
    background: $white !important;
    color: #000000 !important;
    border: 1.5px solid gray !important;
    box-shadow: 0px 6px 6px rgb(220 236 239 / 25%) !important;
}

.suspense-other-checkbox {
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.inbound-pending-utr{
    width: 118px;
}

.inbound-paid-utr {
    width: 175px;
}

.inbound-pending-fin-select {
    width: 100px;
}

.inbound-amount-received-filter {
    width: 136px;
}