.manage-task-outer{
    background: $white;
    border-radius: 8px;
    display: inline-block;
    width: 100%;
    padding: 20px;
    .loan-card-heading{
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: $heading-color;
        margin-bottom: 30px;
        display: inline-block;
        width: 100%;
    }
    .manage-loan-card-outer{
        display: inline-block;
        width: 100%;
        height: calc(100vh - 207px);
        overflow-y: auto;
    }
    .manage-loan-card-detail{
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 15px;
        
        .loan-id-heading{
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            color: $heading-color;
             display: block;
             padding-bottom: 10px;
        }
        ul{
            li{
                display: flex;
                align-items: flex-start;
                padding-bottom: 8px;
                span{
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    display: flex;
                    padding-left: 5px;
                    color: $heading-color;
                }
            }
        }
        .btn-whatsapp-email{
            padding-top: 10px;
            .btn-primary{
                min-width: auto;
                padding: 0px 15px;
                display: flex;
                align-items: center;
                img{
                    margin-right: 5px;
                }
            }
            .btn-line{
                min-width: auto;
                padding: 0px 15px;

            }
        }
    }
    .to-do-list{
        background: #FCF6DE;
    }
    .response-card{
        background: #FFEEEE;
    }

    .completed-card{
        background: #E1FDEB;
    }

    .archived-card{
        background: #EEF0FF;
    }

}
.date-sub-heading{
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
}

.sales-task-table-outer{
    overflow-x: auto;
    background: $white;
    span{
        border-bottom: 1px solid $border-color;
        display: inline-block;
        width: 100%;
        padding-bottom: 10px;
    }
    tr{
        th,td{
            white-space: nowarp;
            border-right: 1px solid $border-color;
            width: 260px;
            text-align: center !important;
            padding-right: 0px !important;
            padding-left: 0px !important;
            &:first-child{
                width: 110px;
                padding-right: 15px !important;
                padding-left: 15px !important;
                text-align: left !important;
                position: sticky;
                left: 0px;
                top: 0px;
                z-index: 2;
            }
            
            &.overall-data-row{
                width: 350px;
            }
        }
        
        td{
            &:first-child{
            background: $white;
            }
        }
        th{
            &:first-child{
                background: #e3e7f1;
                }
            tr{
              &:hover{
                background: none;
              }  
            }
        }
        
    }
    .sub-table{
      tr{
        &:hover{
            background: none;
        }
      }
        th,td{
            white-space: nowrap;
            border-right: 0px;
            width: 80px;
            text-align: left;
            padding-right: 10px !important;
            padding-left: 10px !important;
            &:first-child{
                width: 80px;
                text-align: center !important;
                background: none;
                position: inherit !important;
            }
        }
    }
    tfoot{
        tr{
            background: #e3e7f1;
            border-radius: 6px;
            td{
                &:first-child{
                    background: #e3e7f1;
                }
            }
        }
    }
}

.sales-task-info{
    background: #EFF2F9;
    width: 100%;
    border-radius: 10px;
    ul{
        display: flex;
        flex-wrap: wrap;
        li {
            width: calc(37.33% - 20px);
            padding: 20px;
            label {
                display: block;
                width: 100%;
                font-size: 10px;
                color: rgb($heading-color, 0.7);
                font-weight: 400;
            }
            span {
                font-size: 13px;
                color: $heading-color;
            }
            
        }
    }
}

.monitoring-outer {
    .cust-heading {
        min-width: auto;
        width: 130px;
    }
    .bucket-heading {
        min-width: auto;
        width: 120px;
    }
    .subbucket-heading {
        min-width: auto;
        width: 145px;
    }
    .reason-heading {
        min-width: auto;
        width: 140px;
    }
}

.proof-upload {
    .filelabel {
        width: 200px;
        border: 2px solid #D4E0EB;
        border-radius: 5px;
        display: block;
        padding: 20px 10px;
        transition: border 300ms ease;
        cursor: pointer;
        text-align: center;
        margin: 20px 0px;
        background: #F4F7FA;
    }

    .filelabel i {
        display: block;
        font-size: 30px;
        padding-bottom: 5px;
    }

    .filelabel i,
    .filelabel .title {
        color: grey;
        transition: 200ms color;
    }

    // .filelabel:hover {
    //   border: 2px solid #1665c4;
    // }
    // .filelabel:hover i,
    // .filelabel:hover .title {
    //     color: #1665c4;
    // }

    #FileInput {
        display: none;
    }

}

.w-200{
    width: 200px !important;
}